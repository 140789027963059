const StatusIntegracaoSeniorFuncionarioItemEnum = Object.freeze({
    PENDENTE: 'Pendente',
    PROCESSANDO: 'Processando',
    PREPARADA: 'Preparada',
    FINALIZADA: 'Finalizada',
    ATIVIDADE_ESPECIFICA: 'Atividade específica',
    ERRO: 'Erro',
    NAO_LOCALIZADO: 'Não localizado',
    CANCELADO: 'Cancelado'
})

export default StatusIntegracaoSeniorFuncionarioItemEnum;
