<template>
    <div>
        <AppPaginatedGrid 
            ref="grid"
            :service="service"
            :actionItems="actionItems"
            @onToggleMenu="onToggleMenu"
            :filtrosExtras="filtrosExtras"
            :hasFiltro="false"
        >
            <template #columns>
                <Column field="name" header="Funcionário" />
                <Column field="status" header="Status">
                    <template #body="{ data }">
                        <Tag
                            v-tooltip="getTooltipError(data)"
                            :style="tagStatusStyle(data.status)"
                            :value="enumStatusIntegracaoSeniorFuncionarioItem[data.status]"
                        />
                    </template>
                </Column>
                <Column field="erro" header="Erro" />
            </template>

            <template #anotherFilters>
                <div class="flex flex-column p-fluid mt-2 md:mt-0 justify-items-between gap-2">
                    <Chips v-model="filtrosExtras.nomeFuncionarios" placeholder="Pesquisar" class="md:w-10"/>
                    <div class="field-checkbox">
                        <Checkbox id="filtroPossuiErro" :binary="true" v-model="filtrosExtras.possuiErro" />
                        <label for="filtroPossuiErro">Mostrar apenas com erro</label>
                    </div>
                </div>
            </template>
        </AppPaginatedGrid>
        <DialogIntegracaoSeniorItem ref="dialogIntegracaoSeniorItem"/>
    </div>
</template>

<script>
import BaseService from '../../../services/BaseService';
import DialogIntegracaoSeniorItem from './DialogIntegracaoSeniorItem';
import StatusIntegracaoSeniorFuncionarioItemEnum from '../../../enums/StatusIntegracaoSeniorFuncionarioItemEnum';
import { showError, showSuccess, showWarning } from '../../../utils/Toast';
import { getClientBase } from '../../../services/http';

export default {
    components: {
        DialogIntegracaoSeniorItem
    },
    data() {
        return {
            filtrosExtras: {
                possuiErro: false,
                nomeFuncionarios: []
            },
            service: null,
            enumStatusIntegracaoSeniorFuncionarioItem: StatusIntegracaoSeniorFuncionarioItemEnum,
            actionItems: [
                {
                    label: 'Visualizar',
                    icon: 'pi pi-eye',
                    command: () => this.visualizar(this.record)
                },
                {
                    label: 'Reprocessar',
                    icon: 'pi pi-replay',
                    command: async () => await this.reprocessar(this.record)
                },
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times',
                    command: async () => await this.cancelar(this.record)
                }
            ]
        };
    },
    created() {
        this.service = new BaseService(`/integracao-senior-funcionario/items/${this.$route.params.id}`);
    },
    watch: {
        'filtrosExtras.possuiErro'() {
            this.$refs.grid.load();
        },
        'filtrosExtras.nomeFuncionarios'() {
            this.$refs.grid.load();
        }
    },
    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        },
        visualizar(record) {
            this.$refs.dialogIntegracaoSeniorItem.open(record);
        },
        async cancelar(record) {
            try {
                if (
                    StatusIntegracaoSeniorFuncionarioItemEnum[record.status] == StatusIntegracaoSeniorFuncionarioItemEnum.CANCELADO ||
                    StatusIntegracaoSeniorFuncionarioItemEnum[record.status] == StatusIntegracaoSeniorFuncionarioItemEnum.FINALIZADA ||
                    StatusIntegracaoSeniorFuncionarioItemEnum[record.status] == StatusIntegracaoSeniorFuncionarioItemEnum.ATIVIDADE_ESPECIFICA
                ) {
                    showWarning(this.$toast, 'Não é possível cancelar um registro com este status.');
                    return;
                }
                await getClientBase().post(`integracao-senior-funcionario/cancelar-item-integracao/${record.id}`);
                await this.$refs.grid.load();
                showSuccess(this.$toast, 'Registro cancelado!');
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async reprocessar(record) {
            try {
                if (
                    StatusIntegracaoSeniorFuncionarioItemEnum[record.status] == StatusIntegracaoSeniorFuncionarioItemEnum.FINALIZADA ||
                    StatusIntegracaoSeniorFuncionarioItemEnum[record.status] == StatusIntegracaoSeniorFuncionarioItemEnum.PREPARADA ||
                    StatusIntegracaoSeniorFuncionarioItemEnum[record.status] == StatusIntegracaoSeniorFuncionarioItemEnum.PROCESSANDO ||
                    StatusIntegracaoSeniorFuncionarioItemEnum[record.status] == StatusIntegracaoSeniorFuncionarioItemEnum.ATIVIDADE_ESPECIFICA ||
                    StatusIntegracaoSeniorFuncionarioItemEnum[record.status] == StatusIntegracaoSeniorFuncionarioItemEnum.CANCELADO
                ) {
                    showWarning(this.$toast, 'Status inválido para reprocessamento.');
                    return;
                }
                const service = new BaseService('/integracao-senior-funcionario/reprocess/item');
                service.save(record);
                showSuccess(this.$toast, 'Reprocessamento solicitado. Atualize para acompanhar.');
                await this.$refs.grid.load();
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        getTooltipError(data) {
            if (
                StatusIntegracaoSeniorFuncionarioItemEnum[data.status] === StatusIntegracaoSeniorFuncionarioItemEnum.ERRO ||
                StatusIntegracaoSeniorFuncionarioItemEnum[data.status] === StatusIntegracaoSeniorFuncionarioItemEnum.NAO_LOCALIZADO 
            ) {
                return `Não foi possível processar o registro. Erro: ${data.erro}`;
            }

            return '';
        },
        tagStatusStyle(status) {
            if (
                StatusIntegracaoSeniorFuncionarioItemEnum[status] === StatusIntegracaoSeniorFuncionarioItemEnum.NAO_LOCALIZADO ||
                StatusIntegracaoSeniorFuncionarioItemEnum[status] === StatusIntegracaoSeniorFuncionarioItemEnum.ERRO
            ) {
                return {
                    'background-color': 'var(--pink-200)',
                    color: 'var(--pink-800)'
                };
            }

            if (
                StatusIntegracaoSeniorFuncionarioItemEnum[status] === StatusIntegracaoSeniorFuncionarioItemEnum.FINALIZADA ||
                StatusIntegracaoSeniorFuncionarioItemEnum[status] === StatusIntegracaoSeniorFuncionarioItemEnum.ATIVIDADE_ESPECIFICA
            ) {
                return {
                    'background-color': 'var(--green-300)',
                    color: 'var(--green-900)'
                };
            }

            return {
                'background-color': 'var(--blue-200)',
                color: 'var(--blue-900)'
            };
        }
    }
};
</script>
